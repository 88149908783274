import React,  { useState, useEffect }  from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";
import SearchBar from "../../../../../components/Common/SearchBar";
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldNumber from "../../../../../components/Common/FormFieldNumber";
import FormFieldComboArray from "../../../../../components/Common/FormFieldComboArray";
import FormFieldFiles from "../../../../../components/Common/FormFieldFiles";
import FormFieldFileUpload from "../../../../../components/Common/FormFieldFileUpload";
import MUIDataTable from "mui-datatables";
import { COLUMNS_MANTENIMIENTO_PREVENTIVO} from "./MantenimientoPreventicoColumnasConf"
import { TEXT_LABELS } from "../../../../../configuraciones/conf";
import AddButtonToolbar from "../../../../../components/Common/AddButtonToolBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
}));

const FormularioMaquinaria = () => {
    const methods = useForm();
    const classes = useStyles();
    const { control, handleSubmit} = useForm(); 
    const history = useHistory(); 

    let { id, modo } = useParams();      
    const [campos, setCampos] = useState([]) 
    const [openAlert, setOpenAlert] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true); 
    const [idCategoria, setIdCategoria] = useState(0)
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])  
    const [maquinaria, setMaquinaria] = useState([])
    const [pdfMaquinaria, setpdfMaquinaria]= useState('');
    const [mantenimientos, setMantenimientos] = useState([])
    const [open, setOpen] = useState(false) 
    const [tipoMantenimientoPreventivo, setTipoMantenimientoPreventivo] = useState({Id: 0, Etiqueta: "", Activo: 0})

  let CAMPOS_MANTENIMIENTO_PREVENTIVO = [
      {
          urlData: "dic/diccionarios/mantenimientoperiodicidad",
          campoEtiqueta: "Etiqueta",
          required: true,
          label: "Periodicidad",
          name: "periodicidad",
          type: "combo",  
          value: '',
          disabled: false, 
          multiple:false,
          onChangeValue: ( value ) => {
              setTipoMantenimientoPreventivo(value)
          },
          xs:3
      },    
      {
          urlData: "dic/diccionarios/diasdelasemana",
          campoEtiqueta: "diasemana",
          required: false,
          label: "Día de la semana",
          name: "diasemana",
          disabled: true,
          type: "combo",           
          multiple:false,   
          value: '',
          xs:3,
      }, 
      {
          required: false,
          label: "Día del mes:",
          name: "diames",
          type: "date",    
          value: "",
          disabled: true,
          xs:3
      },
      {
          required: false,
          label: "Día del año:",
          name: "diaanio",
          type: "date",    
          value: "",
          disabled: true,
          xs:3
      },
      {    
          required: true,
          label: "Título:",
          name: "titulo",
          type: "text",    
          disabled: false, 
          value: '',                
          xs:12
      }, 
      {
          required: true,
          label: "Descripción",
          name: "descripcion",
          disabled: false, 
          type: "textArea",
          lineas: 4,                
          value: '',
          xs:12
      },
  ];

    useEffect(() => {

      setCampos([
      //0 CATEGORÍA
      {
        urlData: "dic/diccionarios/categoriasrecursospropios",
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "Categoria",
        disabled: modo === "R" ? true : false,
        type:'combo',
        label: "Categoría",
        value: '',
        multiple: false,
        xs: 6
      },     
      //1 SUBCATEGORÍA
      {
        urlData: `dic/diccionarios/subcategoriasrecursospropios/${idCategoria}`,
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "Subcategoria",
        disabled: modo === "R" ? true : false,
        type:'combo',
        label: "Subcategoría",
        value: '',
        multiple: false,
        xs: 6
      }, 
      //2 Nombre
      {
        urlData: "",
        required: true,
        name: "Nombre",
        disabled: modo === "R" ? true : false,
        label: "Nombre",
        type: 'text',
        value: '',
        multiple: false,
        xs: 12
      },  
      //3 Marca
      {
        urlData: "",
        required: true,
        name: "Marca",
        disabled: modo === "R" ? true : false,
        label: "Marca",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },  
      //4 Modelo
      {
        urlData: "",
        required: false,
        name: "Modelo",
        disabled: modo === "R" ? true : false,
        label: "Modelo",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },  
      //5 Matrícula
      {
        urlData: "",
        required: false,
        name: "Matricula",
        disabled: modo === "R" ? true : false,
        label: "Matrícula / Serie",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },  
      //6 MPL
      {
        urlData: "",
        required: true,
        name: "MPL",
        disabled: modo === "R" ? true : false,
        label: "MPL Autoridad Portuaria",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },    
      //7 Ubicación
      {
        urlData: "",
        required: false,
        name: "Ubicacion",
        disabled: modo === "R" ? true : false,
        label: "Ubicación",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },  
      //8 Empresa
      {
        urlData: "",
        required: true,
        name: "Empresa",
        disabled: modo === "R" ? true : false,
        label: "Empresa",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },  
      //9 Fecha Vencimiento Lloyd
      {
        required: true,
        label: "Vencimiento Lloyd's",
        name: "VencimientoLloyd",
        disabled: modo === "R" ? true : false,
        type: "date",    
        value: '',
        xs: 3
      },  
      //10 Nº Póliza Seguro
      {
        urlData: "",
        required: false,
        name: "PolizaSeguro",
        disabled: modo === "R" ? true : false,
        label: "Nº Póliza seguro",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },     
      //11 Empresa Póliza Seguro
      {
        urlData: "",
        required: false,
        name: "EmpresaSeguro",
        disabled: modo === "R" ? true : false,
        label: "Compañía seguro",
        type: 'text',
        value: '',
        multiple: false,
        xs: 3
      },     
      //12 Cuota seguro
      {
        urlData: "",
        required: false,
        name: "CuotaSeguro",
        disabled: modo === "R" ? true : false,
        label: "Cuota seguro",
        type: 'number',
        value: '',
        multiple: false,
        xs: 3
      }, 
      //13 Fecha Abono seguro Lloyd
      {
        required: false,
        label: "Fecha abono seguro",
        name: "FechaAbonoSeguro",
        disabled: modo === "R" ? true : false,
        type: "date",    
        value: '',
        xs: 3
      },  
      //14 Coste turno
      {
        urlData: "",
        required: false,
        name: "Coste",
        disabled: modo === "R" ? true : false,
        label: "Coste Turno",
        type: 'number',
        value: '',
        multiple: false,
        xs: 3
      }, 
      //15 Estado 
      {
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Estado",
        name: "Activo",
        disabled: modo === "R" ? true : false,
        type: "comboArray", 
        options:[{Id: '1', Etiqueta: 'Activo'}, {Id: '0', Etiqueta: 'Inactivo'}], 
        multiple:false,   
        value: "",
        xs:3
      },  
      //16 Observaciones
      {
        required: false,
        name: "descripcion",
        disabled: modo === "R" ? true : false,
        label: "Observaciones",
        value: '',
        type: 'textarea',
        lineas:3,
        xs: 12
      },   
      //17
      {
        required: false,
        label: "Documentacion",
        name: "ficherosadjuntos",
        type: "filesupload",   
        tabla: "mtms_recursos_adjuntos",
        //xs:3
      },
      //18
      {
        required: false,
        label: "",
        name: "ficherosremotos",
        type: "files", 
        ficheros: [], //mantenimiento.ficheros,    
        tabla: "mtms_recursos_adjuntos",
        modelo: "api\\modules\\v1\\models\\recursos\\RecursosAdjuntos",
        carpeta: "recursos",
        //xs:3
      } 
      ])
    
      const fetchData = async () => {  
        setIsLoading(true) 
        if (modo === "W" || modo === "R") {
          const respPedido = await axios(
            `${process.env.REACT_APP_API_URL}recursos/${id}`
          );  
          if (Object.keys(respPedido.data).length > 0) {
            setMaquinaria(respPedido.data)
             
          }
          if(id){
            const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}mantenimientopreventivos/recurso/${id}`);    
            setMantenimientos(respOportunidad.data) 
          }
          
        }
        setIsLoading(false);
      }
      fetchData(); 
      
    },[modo]) 

    useEffect(() => {
      if(idCategoria){
        campos[1].urlData = `dic/diccionarios/subcategoriasrecursospropios/${idCategoria}`
      } else {
        if(campos.length > 0){
          campos[1].urlData = `dic/diccionarios/subcategoriasrecursospropios/0`
        }
      }     
    },[idCategoria])

    useEffect(() => {
      if(maquinaria && Object.keys(maquinaria).length > 0){
        const options= [{Id: 0, Etiqueta: 'Inactivo'}, {Id: 1, Etiqueta: 'Activo'}];
        const activo = options.filter((element)=> element.Id === maquinaria.Activo);
        campos[0].value = maquinaria.Categoria;
        campos[1].value = maquinaria.Subcategoria;
        campos[2].value = maquinaria.Nombre;
        campos[3].value = maquinaria.Marca;
        campos[4].value = maquinaria.Modelo;
        campos[5].value = maquinaria.Matricula;
        campos[6].value = maquinaria.MPL;
        campos[7].value = maquinaria.Ubicacion;
        campos[8].value = maquinaria.Empresa;
        campos[9].value = maquinaria.VencimientoLloyd;
        campos[10].value = maquinaria.PolizaSeguro;
        campos[11].value = maquinaria.EmpresaSeguro;
        campos[12].value = maquinaria.CuotaSeguro;
        campos[13].value = maquinaria.FechaAbonoSeguro;
        campos[14].value = maquinaria.Coste;
        campos[15].value = activo[0];
        campos[16].value = maquinaria.Descripcion;
        campos[18].ficheros = maquinaria.ficheros;
        
      }
    }, [maquinaria])

    useEffect(() => {
    
    if(tipoMantenimientoPreventivo.Id === 1){
        CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = false
        CAMPOS_MANTENIMIENTO_PREVENTIVO[2].disabled = true
        CAMPOS_MANTENIMIENTO_PREVENTIVO[3].disabled = true
    }
    if(tipoMantenimientoPreventivo.Id === 2){
      CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = true
      CAMPOS_MANTENIMIENTO_PREVENTIVO[2].disabled = false
      CAMPOS_MANTENIMIENTO_PREVENTIVO[3].disabled = true
    }
    if(tipoMantenimientoPreventivo.Id === 3){
      CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = true
      CAMPOS_MANTENIMIENTO_PREVENTIVO[2].disabled = true
      CAMPOS_MANTENIMIENTO_PREVENTIVO[3].disabled = false
    }

    }, [tipoMantenimientoPreventivo])


    const accion = () =>{
      setOpen(true)
    };

    const OPTIONS_MANTENIMIENTO_PREVENTIVO = {
      filterType: 'checkbox',
      print: false,
      download: false,
      pagination: false,
      selectableRows: 'single',
      expandableRows: false,
      selectableRowsHeader: false,
      selectableRowsHideCheckboxes: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      elevation:0,
  
       customToolbar: () => {
        return (
          <AddButtonToolbar 
          title = "Formulario Mantenimiento Preventivo"
          urlForm = "mantenimientopreventivos" 
          action = {accion}
          cerrar={open}
          urlGoto = "" // vacio 
          campos = {CAMPOS_MANTENIMIENTO_PREVENTIVO}
          toolTipTitle = "Añadir mantenimiento preventivo"
          type = "add"
          value = {id}
          />
        );
      },    
      textLabels: TEXT_LABELS,
    };

    const handleSubmitPost = async (datos) => {
      
      datos.ficheros = ficheros
      datos.nombresFicheros = nombresFicheros

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}recursos`

      if (modo==="W") {
        await axios
          .put(`${SERVER_URL}/${id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {    
            if(!isNaN(response.data)){
              if(parseInt(response.data) === parseInt(id)){                       
                  setOpenAlert(true)  
                  
                  setTimeout(function () {
                    window.location.reload();   
                }, 1000);
                
                             
              }
            }
            })
            .catch((error) => {
              console.log("error " + error);
              if( error.response ){
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
              //alert("Ha ocurrido un error al eliminar. " + error.response.data.message)
              }
        });
      }
      else{
        await axios
          .post(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {  
            if(!isNaN(response.data)){
              if(parseInt(response.data)){                       
                  setOpenAlert(true)
                  setTimeout(function () {
                    window.location.reload();
                }, 1000);
              }
            }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
        });
      }

    }

    const handleCloseAlert = (event, reason) => {  
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };

    const handleChangeCategoria = (valor) => {
      if(valor){
        setIdCategoria(valor.Id)
      }
    }

    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    const generarPDF =  () => {    
      setIsLoading(true);
        const fetchData = async () => {
          try{
          const respMaquinaria = await axios( process.env.REACT_APP_API_URL + "recursos/generapdf/" + id);
          if (respMaquinaria.data) {
            setpdfMaquinaria(respMaquinaria.data);            
          }
          }catch(error){
            console.log(error)
          }
          finally{
              setIsLoading(false);
          }
        };
        fetchData();      
    }

    const botoneraAcciones = [  
      
      {
        etiqueta: modo === "R" ? "Editar Operación" : "Cancelar Edición",
        url: 
        typeof id === "undefined" ? `/operativas/operaciones/` :
        modo === "R" ? 
          `/servicio/maquinarias/fichamaquinaria/${id}/W` : 
          `/servicio/maquinarias`,          
        activo: true,
      }, 
      {
        etiqueta: "Guardar",
        form:'formulario-ficha-maquinaria',
        activo: modo === "R" ? true : true,
      },
      {
        etiqueta: "Generar documento",
        function: generarPDF,
        activo: modo === "R" ? false : true,
      },
      {        
        etiqueta: "Ver documento",
        file: pdfMaquinaria ? pdfMaquinaria : "",
        activo: pdfMaquinaria ? true : false,        
      }   
    ]; 

    const urlsMigasdepan = [
      {titulo: "Maquinaria", colorTexto: "texto-comercial", urlTo: "/servicio/maquinarias"},
      {titulo: "Maquinaria", colorTexto: "texto-comercial", urlTo: `/servicio/maquinarias/fichamaquinaria/${id}/${modo}`},
    ]  
    
    return(
      <>
      <div className="col-8 col-main-home ">  
      <SearchBar
          titulo="Operativas"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-4-1-mantenimiento.png"}
        />   

        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
          <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={''}/>       
           <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">           

          {!isLoading && (
            <>
            {/* FORMULARIO */}
              <div className={classes.root}>
                  <FormProvider {...methods}>
                    <form
                      id='formulario-ficha-maquinaria'
                      onSubmit={handleSubmit((data) => {
                      handleSubmitPost(data);
                      })}
                      className=""
                    >
                    <Grid className="borderFormulario" container spacing={1}> 
                      <FormFieldCombo propiedades={campos[0]} control={control} onChangeValue={handleChangeCategoria}/>
                      <FormFieldCombo propiedades={campos[1]} control={control} />
                      <FormFieldText propiedades={campos[2]} control={control}/>
                      <FormFieldText propiedades={campos[3]} control={control}/>
                      <FormFieldText propiedades={campos[4]} control={control}/>
                      <FormFieldText propiedades={campos[5]} control={control}/>
                      <FormFieldText propiedades={campos[6]} control={control}/>
                      <FormFieldText propiedades={campos[7]} control={control}/>
                      <FormFieldText propiedades={campos[8]} control={control}/>
                      <FormFieldDate propiedades={campos[9]} control={control}/>
                      <FormFieldText propiedades={campos[10]} control={control}/>
                      <FormFieldText propiedades={campos[11]} control={control}/>
                      <FormFieldNumber propiedades={campos[12]} control={control}/>
                      <FormFieldDate propiedades={campos[13]} control={control}/>
                      <FormFieldNumber propiedades={campos[14]} control={control}/>
                      <FormFieldComboArray propiedades={campos[15]} control={control}/>
                      <FormFieldTextArea propiedades={campos[16]} control={control}/>
                      <FormFieldFiles propiedades={campos[18]} control={control} ficheros={handleFicheros}/>
                      <FormFieldFileUpload propiedades={campos[17]} control={control} handleFicheros={handleFicheros}/>
                    </Grid>
                    </form>
              </FormProvider>          
            
              {openAlert && 
              <AlertaMensaje 
                mensaje={"Maquinaria guardada correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
              } 
              </div>
              
              {id && (
                <div className="mt-4">
                  <h6 className="texto-comercial" id="title">
                      Mantenimiento Preventivo
                  </h6>
                  <div className="rounded border border-formulario p1-form">
                      <MUIDataTable
                        title={""}
                        className={"mt-1"}
                        data={mantenimientos}
                        columns={COLUMNS_MANTENIMIENTO_PREVENTIVO}
                        options={OPTIONS_MANTENIMIENTO_PREVENTIVO}
                      />
                  </div>
                </div>
              )}
          
            </> 
          )}
          </div>
      </div>
          <TableAcciones
            filaSeleccionada={-1}
            botonaccion={botoneraAcciones}
          />
        </div>


      <div>
        {isLoading && 
          <div className={classes.linear}>
                <LinearProgress />
          </div>
        }
      </div>

      </div>
      </>
    )
}
export default FormularioMaquinaria;