import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class MenuizqProduccion extends Component {
  render() {
    const { isAuthUser } = this.props.reducerApi;    

    return (
      <div className="col-12 p-0">
        <div className="card mb-2">
          <div className="bg-produccion p-0" id="headingProduccion">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-white"
                type="button"
              >
                PRODUCCIÓN
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-caret-down-fill float-right"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </button>
            </h2>
          </div>

          <div className="wrapper card-header p-2 ">
            <nav id="sidebar">
              <ul className="list-unstyled components">
                <li className="  mb-1">
                  <a
                    href="#logisticaSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle p-produccion"
                  >
                    Logística interna
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2 "
                    id="logisticaSubmenu"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/logistica/existencias" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Existencias"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/logistica/materiales" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Materiales"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/logistica/depositos" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Almacenes"}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className=" mb-1">
                  <a
                    href="#operativasSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle p-produccion"
                  >
                    Operativas
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2"
                    id="operativasSubmenu"
                  >
                    <li className="nav-item p-produccion">
                      <NavLink
                        to={isAuthUser ? "/operativas/planningsemanal" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Planning Semanal"}
                      </NavLink>
                    </li>

                    <li className="nav-item p-produccion">
                      <NavLink
                        to={isAuthUser ? "/operativas/planificaciones" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Planificación"}
                      </NavLink>
                    </li>
                    <li className="nav-item p-produccion">
                      <NavLink
                        to={isAuthUser ? "/operativas/operaciones" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Operaciones"}
                      </NavLink>
                    </li>
                    <li className="nav-item p-produccion">
                      <NavLink
                        to={isAuthUser ? "/operativas/almacenamiento" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Almacenamiento"}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className=" p-produccion mb-1">
                  <a
                    href="#postventaSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Postventa
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2"
                    id="postventaSubmenu"
                  >
                    <li className="nav-item">                      
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/postventa/soportes" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Soporte"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/postventa/auditoria" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Auditoría"}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className=" p-produccion mb-1">
                  <a
                    href="#servicioSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Servicios
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2"
                    id="servicioSubmenu"
                  >
                  <li className="nav-item p-produccion">
                      <NavLink
                        to={isAuthUser ? "/servicio/planningmantenimiento" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Planning Mantenimiento"}
                      </NavLink>
                    </li>
                    <li className="nav-item">                      
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/servicio/maquinarias" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Maquinaria"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/servicio/instalaciones" : "/"}
                        activeClassName="activeProduccion"
                      >
                        {"Instalaciones"}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuizqProduccion);
