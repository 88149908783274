import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Button, CircularProgress } from '@material-ui/core';
import "semantic-ui-css/semantic.min.css";
import MUIDataTable from "mui-datatables";
import {TEXT_LABELS} from "../../configuraciones/conf"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from "@material-ui/core/TablePagination";

import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

let theme = createMuiTheme({
  overrides: {        
    MUIDataTableSelectCell: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableToolbarSelect: {
      title: {
        display: 'none'
      }
    }
  }
});

const CuerpoTabla = ({
    titulo, 
    columnas, 
    urlDatos,
    urlDoubleClick,
    columnId,
    getFilaSeleccionada,
    modo,
    ordenColumnas = null,
    filtroToolbar = false,
    filtroToolbarOpciones = [],
    filtroToolbarValor = '',
    filtroToolbarChangeValor = null,
    getDatosFilaSeleccionada = null,
    recargar = false,
    getUrlSeleccionada = null,
    customFooter = null,
    filtroEjercicio = false,
    paginacion = true, 
    returnData = null
    }) => { 

    const [datos, setDatos] = useState([]); 
    const [datosChanged, setDatosChanged] = useState([]);
    const history = useHistory(); 
    const [isLoading, setIsLoading] = useState(true)
    const [valorToolbar, setValorToolbar] = useState(filtroToolbarValor);
    const [ejercicio, setEjercicio] =  useState(new Date().getFullYear());
    const [filtros, setFiltros] = useState([]);

    const ejercicioActual = new Date().getFullYear();
    let ejerciciosArray = []
    for (let i = ejercicioActual; i > ejercicioActual-5; i--) {
      ejerciciosArray.push(i);
    }

    const handleChange = (event) => { 
      if(filtroToolbarChangeValor) filtroToolbarChangeValor(event.target.value);
      setValorToolbar(event.target.value);
    };

    const handleChangeEjercicio = (event) => { 
      //console.log(event.target.value)
      setEjercicio(event.target.value);
    };

    const onRowsSelect = (curRowSelected, allRowsSelected) => {   
        if(getFilaSeleccionada) getFilaSeleccionada(datos[curRowSelected[0].dataIndex][columnId])
        if(getUrlSeleccionada) getUrlSeleccionada(urlDoubleClick)
        if(getDatosFilaSeleccionada)
          getDatosFilaSeleccionada(datos[curRowSelected[0].dataIndex])
        if(allRowsSelected < 1){
          if(getFilaSeleccionada) getFilaSeleccionada(-1)
        }
    }
    
    const handleRowDoubleClick = (item, index) => {    
        let modoaux = modo ? modo : "R"
        if(urlDoubleClick){
          history.push({
            pathname: `${urlDoubleClick}${item[0]}/${modoaux}`,       
          });
        }
    }

    const fetchData = async () => {
      let urlEjercicio = filtroEjercicio ? "ejercicio=" + ejercicio : "";
      if(urlDatos.includes("?"))
        urlEjercicio = "&" + urlEjercicio;
      else
        urlEjercicio = "?" + urlEjercicio;
      //console.log(urlEjercicio)
      //console.log(urlDatos)
      //console.log(process.env.REACT_APP_API_URL + urlDatos + urlEjercicio)
      axios.get(process.env.REACT_APP_API_URL + urlDatos + urlEjercicio)
      .then((response) => {
        //console.log(response.data)
        //console.log(returnData)
        if(returnData) returnData(response.data)
        setDatos(response.data); 
        setIsLoading(false)
      })
      .catch((error) => {
          //console.log(error);
          setDatos([]); 
          setIsLoading(false)
      }) 
    }; 

    useEffect(() => { 
      //const getData = async () => { 
        fetchData();
      //};
      //getData();      
  }, [urlDatos, recargar, ejercicio]); 

  useEffect(() => {
    setValorToolbar(filtroToolbarValor)
  }, [filtroToolbarValor])

  const normaliza = (dato) => {
    let arrayDeValores = [];
    let Total = 0

    if(typeof(dato) === 'string'){
      arrayDeValores = dato.split('<br>');

      let arrayNormalizado = arrayDeValores.map(function(x) {
        return parseFloat(x.trim().replaceAll('.', '').replaceAll(',', '.'));
      })

      Total = arrayNormalizado
      .reduce((total, subtotal) => (total += isNaN(parseFloat(subtotal)) ? 0 : parseFloat(subtotal)), 0);
    }
    
    return Total
  }

  const handleFilterSubmit = (applyFilters)  => {    
    let filterList = applyFilters();
    setFiltros(filterList);
  };
  
  function handleSearchChange(searchText) {
    //console.log(datos)    
    let arrayKeys = Object.keys(datos[0]);
    //console.log(arrayKeys);
    const result = datos.filter(function(obj) {
      //console.log(obj)
      let isResultInArray = false;
      arrayKeys.forEach((element) => {
        //console.log(element)
        //console.log(obj[element])
        //console.log(searchText)
        //if(obj[element] === searchText)
        if(obj[element]?.includes(searchText))
          isResultInArray = true;
        //return obj[element] === searchText
      }
      );
      if(isResultInArray) return true;
      else return false;

    })
    //console.log(result)
    setDatosChanged(result)
    /*
    var result = datos.filter(obj => {      
      return (
        obj.Id === searchText ||
        obj.NumFactura.includes(searchText)  ||
        obj.RecapitulativaCPE?.includes(searchText) ||
        obj.Total_Factura.includes(searchText) ||
        obj.baseImponible.includes(searchText) ||
        obj.estado.includes(searchText) ||
        obj.fechaFactura.includes(searchText) ||
        obj.importe.includes(searchText) ||
        obj.importeIgic.includes(searchText) ||
        obj.importePartesCPE?.includes(searchText) ||
        obj.irpfImporte.includes(searchText) ||
        obj.origen.includes(searchText) ||
        obj.proveedor.includes(searchText) ||
        obj.tipoOrigen.includes(searchText)
      )
    })

    
    */
   
  }
  

  const OPTIONS_COLUMNS = {       
        filterType: 'dropdown',
        print: false,
        download: true,
        pagination: paginacion,
        selectableRows: 'single',  
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        //tableBodyHeight: '430px',
        tableBodyMaxHeight: customFooter ? '330px' : '470px',
        confirmFilters: true, 
        downloadOptions: {filename: 'datos.xlsx', separator: ',', filterOptions: {useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true}},
        // Calling the applyNewFilters parameter applies the selected filters to the table 
        //onTableChange: handleTableChange,
        onSearchChange: handleSearchChange,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
          return (
            <div style={{ marginTop: '40px' }}>
              <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Aplicar Filtro</Button>
            </div>
          );
        },

        // callback that gets executed when filters are confirmed
        onFilterConfirm: (filterList) => {
          //console.log('onFilterConfirm');
          //console.dir(filterList);
        },
        onFilterDialogOpen: () => {
          //console.log('filter dialog opened');
        },
        onFilterDialogClose: () => {
          //console.log('filter dialog closed');
        },
        onFilterChange: (column, filterList, type) => {
          if (type === 'chip') {
            //console.log(filterList)
            let newFilters = () => (filterList);
            //console.log('updating filters via chip');
            handleFilterSubmit(newFilters);
          }
        },
        elevation:0,  
        onRowSelectionChange: onRowsSelect,
        responsive: "vertical",
        setRowProps: (item, idx) => ({
          onDoubleClick: () => {
            handleRowDoubleClick(item, idx)      
          }
        }),
        customToolbar: () => {
          const FiltroOpciones = () =>
          { 
            return(
              <FormControl >            
                <TextField
                  labelid="almacenamiento-select"
                  className="select-muidatatable"
                  id="almacenamiento-select"
                  value={valorToolbar}
                  select
                  label="Filtrar por"
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                }}            
                >
                  {filtroToolbarOpciones.map((option) => (
                      <MenuItem key={option} value={option}>
                      {option}
                  </MenuItem>
                  ))}              
                </TextField>
              </FormControl>
            )
          }
          
          const FiltroEjercicio = () => {
            return (
              <FormControl >   
                <TextField
                  labelid="ejercicio-select"
                  className="select-muidatatable-ejercicio ml-2"
                  id="ejercicio-select"
                  value={ejercicio}
                  select
                  label="Ejercicio"
                  onChange={handleChangeEjercicio}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}            
                >
                {ejerciciosArray.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}              
                </TextField>
              </FormControl>
            )
          }
          
          return(
            <>
            {filtroToolbar && <FiltroOpciones />}
            {filtroEjercicio && <FiltroEjercicio />}
            </>
          )

        },  
        customFooter: (
          count,
          page,
          rowsPerPage,
          changeRowsPerPage,
          changePage
        ) => {
          
          return (
             <TableFooter>
              <TableRow>
                {
                  customFooter && customFooter.length > 0 && (
                    customFooter.map((campo, index) => (
                      <TableCell style={{fontSize: '1rem', fontWeight: 'bold'}} key={index} colSpan={1} variant={'footer'} align={'right'}>
                        {campo.titulo} : {calculaTotal(campo)}
                      </TableCell>
                    )
                  ))
                }
                
              </TableRow>
              <TableRow>
                <TablePagination 
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={(_, page) => changePage(page)}
                onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
                labelRowsPerPage={"Filas por página"}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                />                
              </TableRow>
            </TableFooter>
          );
        },
         
        onDownload: (buildHead, buildBody, columns, values) => {
          
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          const json = values.reduce((result, val) => {
            const temp = {};
            val.data.forEach((v, idx) => {
              if( (columns[idx].name) !== "urlDocumento" && (columns[idx].display) === "true") {
                let textToNumber = 0;
                if(v)
                  textToNumber = Number(v.replaceAll(".","").replaceAll(",", "."))
                
                if(isNaN(textToNumber)){
                  temp[columns[idx].label] = v;
                }else{
                  //if(v)
                    temp[columns[idx].label] =  textToNumber;
                  //else
                  //  temp[columns[idx].label] = v;
                }
              }
            });
            result.push(temp);
            return result;
          }, []);
    
          const fileName = `Datos`;
          const ws = utils.json_to_sheet(json);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName + fileExtension);
          // cancel default  CSV download from table
          return false;
        },
        textLabels: TEXT_LABELS,
        columnOrder: ordenColumnas,
  };   
    
  const calculaTotal = (campo) => {
    //console.log(campo)
    const filtrosActivos = filtros ? filtros.filter(filtro => filtro.length > 0) : []
    
    let datosFiltrados = datosChanged.length > 0 ? datosChanged.slice() :  datos.slice();
    if(filtrosActivos && filtrosActivos.length > 0){
      let i = 0
      filtros.forEach(filtro => {
        if(filtro.length > 0){
          filtro.forEach(filtroCampo => {
            const found = datosFiltrados.filter(element => element[columnas[i].name] === filtroCampo);
            datosFiltrados = found.slice(); //datosFiltrados.concat(found);
          })
        }
        i++
      })
    }

    const arrayDatosFiltrados = datosFiltrados.slice(); //filtrosActivos.length > 0 ? datosFiltrados.slice() : datos.slice()
    //console.log(arrayDatosFiltrados)

    if(campo && campo.operacion && campo.operacion === 'avg'){
      let Total1 = arrayDatosFiltrados
      .map(a => a[campo.campoNumerador])
      .reduce((total, subtotal) => (total += parseFloat(normaliza(subtotal))), 0);
      let Total2 = arrayDatosFiltrados
      .map(a => a[campo.campoDenominador])
      .reduce((total, subtotal) => (total += parseFloat(normaliza(subtotal))), 0);

      let formatter = new Intl.NumberFormat('de-DE', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });

      return formatter.format(Total1/Total2);
    }

    if(campo && campo.operacion && campo.operacion === 'avgTotal'){
      let Total1 = arrayDatosFiltrados
      .map(a => a[campo.campo])
      .reduce((total, subtotal) => (total += parseFloat(subtotal.replaceAll('.', ','))), 0);
      Total1 = Total1/100;
      let Total2 = arrayDatosFiltrados.length      

      let formatter = new Intl.NumberFormat('de-DE', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });

      return formatter.format(Total1/Total2);
    }

    if(campo && campo.operacion && campo.operacion === 'sum'){
      let Total = arrayDatosFiltrados
      .map(a => a[campo.campo])
      .reduce((total, subtotal) => (total += parseFloat(normaliza(subtotal))), 0);

      let formatter = new Intl.NumberFormat('de-DE');
      return formatter.format(Total);
    }

    //console.log(arrayDatosFiltrados)
    //console.log(campo)
    let Total = arrayDatosFiltrados
    .map(a => a[campo.campo])
    .reduce((total, subtotal) => (total += parseFloat(normaliza(subtotal))), 0);

    //console.log(Total)
    let formatter = null
    if(campo.hasOwnProperty('formato') && campo.formato === "int"){
      formatter = new Intl.NumberFormat('de-DE'); 
    } else{
      formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    }
    
    return formatter.format(Total);
  };
  
  return (
    <>     
    <div className="bg-white p-10px dataTableHeight520">              
      <div className="bg-white">
      { !isLoading && ( 
        <ThemeProvider theme={theme}>
        <MUIDataTable           
          title={""}
          className={"mt-1 mb-2 idDataTable"}
          data={datos}
          columns={columnas}
          options={OPTIONS_COLUMNS}              
        />  
        </ThemeProvider> 
      )}
      { isLoading &&
        <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />
      }                  
      </div>
    </div>          
    </>
    )    
}

export default CuerpoTabla;

